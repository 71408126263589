<template>
    <div>
        <NewProject />
    </div>
</template>


<script>
import NewProject from "../components/NewProject.vue";

export default {
    components: {
        NewProject,
    }
}
</script>
