<template>
    <div class="container">
        <section class="section">
            <div class="container">
                <h1 class="title">New BossDB Project or BossDB Collection</h1>
                <p>
                    BossDB supports free storage and sharing of open data
                    <a href="https://bossdb.org/post/open-data"
                    target="_blank">open data</a> to support your research and
                    publications, with a prioritization of supporting data
                    generated on the BRAIN Initiative
                    <a href="https://bossdb.org/brain_initiative"
                    target="_blank">BRAIN Initiative</a>. BossDB also makes it
                    easy to share your data privately with collaborators, and
                    then — with the flip of a switch — to make it public. BossDB
                    Collections are available to anyone who has a link to the
                    dataset, and so BossDB meets most journals' requirements for
                    unauthenticated data access. BossDB Collections range in
                    size from 10s of GBs to 100s of TBs. More information about
                    our <a href="https://metadata.bossdb.org/DataModel"
                    target="_blank">Data Model</a> and <a
                    href="https://metadata.bossdb.org/"
                    target="_blank">Metadata</a> for BossDB Projects and BossDB
                    Collections, Experiments, and Channels.
                </p>
            </div>
        </section>
        <section class="section">
            <div class="columns">
                <div class="column is-6">
                    <h2 class="subtitle">BossDB Projects</h2>
                    <p>
                        BossDB Projects, each with their own project page on the
                        BossDB website, are typically associated with 1 or more
                        publications. Each BossDB Project points to one or more
                        BossDB Collections, and has a shareable persistent link
                        (to the project page).
                    </p>
                    <p>
                        Public BossDB Projects are searchable through our
                        <a href="https://bossdb.org/projects"
                        target="_blank">BossDB projects</a> page, and are listed
                        on our <a href="https://metadata.bossdb.org/Projects"
                        target="_blank">BossDB Metadata</a> page.
                    </p>
                </div>
                <div class="column is-6">
                    <h2 class="subtitle">BossDB Collections</h2>
                    <p>
                        BossDB Collections contain one or more Experiments,
                        where each Experiment is generally associated with an
                        individual image volume data acquisition effort that
                        contains a single Coordinate Frame, a single main image
                        data Channel, and potentially other registered spatial
                        annotation(s), overlaid segmentation(s), and/or image
                        data Channels. These data Channels each contain a single
                        data type (e.g., uint8, unit16) of multidimensional data
                        registered to the Coordinate Frame.
                    </p>
                    <p>
                        Public BossDB Collections are listed on our <a
                        href="https://metadata.bossdb.org/Projects"
                        target="_blank">BossDB Metadata</a> page.
                    </p>
                </div>
            </div>
        </section>
        <section class="section">
            <p>
                Below outlines a simple process for setting up your new BossDB
                Project and/or BossDB Collection. Given the data storage
                requirements for many BossDB Collections (which can reach 10s to
                100s of TBs), and potential special requirements/approaches to
                facilitate large-scale data ingest, our BossDB team will work
                directly with you to coordinate data transfers and Project Page
                content development and publishing. At any point, please feel
                free to reach out to our team to set up a time to discuss:
                <a href="mailto:info@bossdb.org">info@bossdb.org</a>.
            </p>
        </section>
        <section class="section">
            <details>
                <summary id="new-project" class="subtitle">Create a new BossDB Project</summary>
                <div class="subsection">
                    <ol>
                        <li>
                            <!-- LINK: Google Form (existing) or new Project Form -->
                            <p>
                                Complete our <a href="https://forms.gle/rj9R4M2PQxcFJZ6A6" target="_blank">
                                New BossDB Project Form</a> that provides our team with key information to start preparing
                                your new BossDB Project page and associated Collection. Upon receiving this form, our team
                                will reach out to you to coordinate data ingest and Project page content development
                                and publishing.
                                <br />This form will require information such as:
                            </p>
                            <ul>
                                <li><strong>{{ formInformation[0].title }}</strong>: {{ formInformation[0].description }}</li>
                                <li><strong>{{ formInformation[1].title }}</strong>: {{ formInformation[1].description }}</li>
                                <li>
                                    If a <strong>new BossDB Collection</strong> will be associated with your new BossDB Project:
                                <ul>
                                    <li><strong>{{ formInformation[2].title }}</strong>: {{ formInformation[2].description }}</li>
                                    <li><strong>{{ formInformation[3].title }}</strong>: {{ formInformation[3].description }}</li>
                                    <li><strong>{{ formInformation[4].title }}</strong>: {{ formInformation[4].description }}</li>
                                    <li><strong>{{ formInformation[5].title }}</strong>: {{ formInformation[5].description }}</li>
                                    <li><strong>{{ formInformation[6].title }}</strong>: {{ formInformation[6].description }}</li>
                                </ul>
                                </li>
                                <li>
                                    If an <strong>existing BossDB Collection(s)</strong> will be associated with your new BossDB project:
                                    <ul>
                                        <li><strong>BossDB Collection Name(s)</strong></li>
                                    </ul>
                                </li>
                                <li><strong>{{ formInformation[7].title }}</strong>: {{ formInformation[7].description }}</li>
                            </ul>
                        </li>

                        <li>
                            <strong>Populate Metadata</strong>:
                            Provide information to populate metadata fields for your BossDB Project and any
                            associated BossDB Collections, Experiments, and Channels.
                            You will be emailed links to webforms that will facilitate
                            Metadata entry and publishing. BossDB Project Metadata that
                            you provide will serve as direct text content (and
                            associated images and files) that will be displayed on your
                            BossDB Project page.
                        </li>

                        <li>
                            <strong>Preview/Review</strong>:
                            You will be asked to review the following prior to publishing your new BossDB Project Page
                            <ul>
                                <li>
                                    <strong>BossDB Project Page</strong> content and layout.
                                </li>
                                <li>
                                    <strong>Metadata</strong> that you provided for your BossDB Project, as well as any Metadata provided for any associated Collections, Experiments, and Channels.
                                </li>
                                <li>
                                    <strong>Visualizations</strong> of your data (e.g., neuroglancer, syGlass) as ingests are completed to ensure that data Channels are displayed correctly and segmentation/annotations are aligned properly.
                                </li>
                            </ul>
                        </li>

                        <li>
                            Notify our team that your BossDB Project page is ready to be published and associated BossDB Collections can be made public.
                        </li>
                    </ol>
                </div>
            </details>

            <details>
                <summary id="new-collection" class="subtitle">Create a new stand-alone BossDB Collection</summary>
                <div class="subsection">
                    <p>Note that a stand-alone BossDB Collection will have no associated BossDB Project & Project page.</p>

                    <ol>
                        <li>
                            Complete our <a href="https://forms.gle/rj9R4M2PQxcFJZ6A6" target="_blank">New BossDB Project/Collection Form</a>
                            <!-- New Stand-Alone BossDB Collection Form (TODO: Replace text in <a> once we have collection form) -->
                            that provides our team with key information to start preparing your new BossDB
                            Collection. Upon receiving this form, our team will reach out to you to coordinate
                            data ingest and Metadata population.
                            <br />This form will require information such as:
                            <ul>
                                <li><strong>{{ formInformation[0].title }}</strong>: {{ formInformation[0].description }}</li>
                                <li>
                                    <strong>{{ formInformation[1].title.replace("Project", "Collection") }}</strong>:
                                    For a stand-alone Collection, we recommend that you consider the research goals and
                                    motivation for your data collection, future publications planned, and potential future
                                    BossDB Project Page(s). We would be happy to work with your team to define appropriate titles.
                                </li>
                                <li><strong>{{ formInformation[2].title }}</strong>: {{ formInformation[2].description }}</li>
                                <li><strong>{{ formInformation[3].title }}</strong>: {{ formInformation[3].description }}</li>
                                <li><strong>{{ formInformation[4].title }}</strong>: {{ formInformation[4].description }}</li>
                                <li><strong>{{ formInformation[5].title }}</strong>: {{ formInformation[5].description }}</li>
                                <li><strong>{{ formInformation[6].title }}</strong>: {{ formInformation[6].description }}</li>
                                <li>
                                    If any associations with <strong>existing BossDB Collection(s)</strong> exist with this new BossDB Collection (e.g., affiliated with data, approaches, etc.), please specify which.
                                </li>
                                <li><strong>{{ formInformation[7].title }}</strong>: {{ formInformation[7].description }}</li>
                            </ul>
                        </li>


                        <li>
                            <strong>Populate Metadata</strong>:
                            Provide information to populate metadata fields for your
                            BossDB Collection, Experiments, and Channels.
                            You will be emailed links to webforms that will facilitate
                            Metadata entry and publishing.
                        </li>

                        <li>
                            <strong>Preview/Review</strong>:
                            You will be asked to review the following prior to publishing your new BossDB Collection
                            <ul>
                                <li>
                                    <strong>Metadata</strong> that you provided your BossDB Collection, Experiments, and Channels.
                                </li>
                                <li>
                                    <strong>Visualizations</strong> of your data (e.g., neuroglancer, syGlass) as ingests are completed to ensure that data Channels are displayed correctly and segmentation/annotations are aligned properly.
                                </li>
                            </ul>
                        </li>

                        <li>
                            Notify our team that your BossDB Project page is ready to be published and associated BossDB Collections can be made public.
                        </li>
                    </ol>
                </div>

            </details>

        </section>
    </div>
</template>

<script>

export default {
    data() {
        return {
            formInformation: [
                {
                    title: "Contact Information",
                    description: "This will include your contact name and email address."
                },
                {
                    title: "BossDB Project Title",
                    description: "This almost always conforms to an existing or planned publication citation with author(s) and year (e.g., “Wildenberg et al. 2023”, “Mulcahy et al. 2022”, “Phelps, Hildebrand, Graham et al. 2021”) but we may use other title formats for large-scale/consortium projects and data collections (e.g., “Flywire”, “IARPA MICrONS Minnie”, “Allen Mouse Reference Atlas”). A new BossDB Collection associated with your BossDB Project will use the same title. If multiple new BossDB Collections will be associated with your BossDB Project, we will work with your team to define appropriate titles. You may also associate existing BossDB Collections with your new BossDB Project."
                },
                {
                    title: "Imaging Modality",
                    description: "This is represented in multiple levels of abstraction to facilitate searching / querying, please provide as many levels as applicable (e.g., EM, SEM, ssSEM; X-ray, XRM)."
                },
                {
                    title: "Image Data Format",
                    description: "We support ingest of numerous data formats (e.g., TIFF, Zarr, PNG, precomputed/Cloudvolume, etc.), and upon ingest we will usually convert any data into precomputed object data to be stored in the cloud."
                },
                {
                    title: "Image Voxel Resolution",
                    description: "This can be isotropic (e.g., x=0.9um, y=0.9um, z=0.9um) or anisotropic (e.g., x=4nm, y=4nm, z=35nm). X and Y dimensions are usually reserved for the imaging plane, with Z dimension reserved for the depth (e.g., image tiles are in XY, and Z dimension represents the thickness of the tissue section for serial section imaging, ablation depth for modalities like FIBSEM, or isotropic dimension for modalities like X-ray nanoholotomography)."
                },
                {
                    title: "Image Volume Extent",
                    description: "This can be provided in numbers of voxels (e.g., x=3200, y=3200, z=95), or approximate lengths (e.g., x=320um, y=300um, z=50um)."
                },
                {
                    title: "Approximate Data Storage Size",
                    description: "This is just informational for our team; any estimates could be provided in compressed or uncompressed format."
                },
                {
                    title: "Approximate Publish/Release Date",
                    description: "This is just information for our team; is non-committal and for planning purposes only."
                }
            ],
        }
    }
}
</script>


<style>

details > div > ol {
    margin-left: 1.5em;
}

.subsection {
    padding-bottom: 2rem;
    padding-left: 1rem;
}

ul {
    margin-left: 1.5em;
    list-style: disc;
}

ul > li > ul {
    list-style: circle;

}
</style>
